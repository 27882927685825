import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import '../pages/pages.css'

function Tags(props) {
    let posts = props.data.allMarkdownRemark.edges;
    posts = posts.reverse();
    const { tag } = props.pageContext;
    let storyTitle = "";

    if (tag === 'maya') {
      storyTitle = "Maya of the Golden Moon"
    }
  
    return (
        <Layout className="tags">
            <h1>{`Available posts  for ${storyTitle}`}</h1>
            <div className="taglist">
                {
                    posts.map(({ node }, i) => (
                    <div key={i}>
                        <Link to={node.fields.slug}  >
                            {node.frontmatter.title}
                        </Link>
                    </div>
                    ))
                }
            </div>
        </Layout>
    )
}


export default Tags;


export const query = graphql`

 query TagsQuery($tag: String!) {
 allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
}
`